
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      employer: {
        name: "",
      },
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 10) {
        return value.slice(0, 10) + "...";
      }
      return value;
    },
  },
  computed: {
    isIndustryPage: function () {
      return this.$route.path.includes("/industries");
    },
  },
  methods: {
    logout() {
      this.userLogout({
        router: this.$router,
      });
    },
    goToIndex() {
      this.$router.push({
        path: "/",
      });
    },
    confirmLogout() {
      this.userLogout({
        router: this.$router,
      });
    },

    handleScroll() {
      //    alert("scrolled");

      var nav = document.getElementById("navbar");

      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        nav.classList.add("whitebg");
      } else {
        nav.classList.remove("whitebg");
      }

      var header = document.getElementById("header");
      //  alert(header);
      (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) &&
        header.classList.add("has-scrolled");

      //  $headerApply.addClass("green"),
      //   $menuMobileApply.addClass("show-apply"),
      //  $innerHamburger.addClass("hamburger-scroll")
    },
  },

  created() {
    if (process.client) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  mounted() {
  },
};
