
import { mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "MainFooter",
  data() {
    return {};
  }
};

