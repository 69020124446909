// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../assets/css/index.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../assets/css/fonts.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-title[data-v-a8ae7b90]{font-family:Helvetica;color:#fff;font-size:15px;font-weight:700;line-height:30px}.footer-text[data-v-a8ae7b90]{font-family:Montserrat;color:#abc6e8;font-size:15px;font-weight:500;line-height:30px}.footer-phone-number[data-v-a8ae7b90]{color:#fff;font-size:16px;font-weight:700;line-height:30px}@media(max-width: 768px){.col-no-padding[data-v-a8ae7b90]{padding:0}.footer-text[data-v-a8ae7b90]{line-height:26px}.mobile-footer-margin[data-v-a8ae7b90]{margin-top:20px;margin-bottom:20px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
